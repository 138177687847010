import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';

export default function RadioButtonsGroup(props) {

  const handleChange = (event) => {
    props.setYearValue2(event.target.value);
  };

  const resetToBlank = () => {
    props.setYearValue2([])
  }

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">Filter by Year</FormLabel>
      <RadioGroup aria-label="year" name="year" value={props.yearValue2} onChange={handleChange}>
      <FormControlLabel value="2022" control={<Radio />} label="2022" />
      <FormControlLabel value="2021" control={<Radio />} label="2021" />
      <FormControlLabel value="2020" control={<Radio />} label="2020" />
      <FormControlLabel value="2019" control={<Radio />} label="2019" />
      <FormControlLabel value="2018" control={<Radio />} label="2018" />
        <FormControlLabel value="2017" control={<Radio />} label="2017" />

     
    
    
   
       

      </RadioGroup>

      <Button type="submit" onClick={(e) => resetToBlank()} variant="outlined" color="primary" >
       Reset
        </Button>
    </FormControl>
  );
}