/* eslint-disable no-use-before-define */
import React from 'react';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import FormLabel from '@material-ui/core/FormLabel';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 500,
    '& > * + *': {
      marginTop: theme.spacing(.5),
    },
  },
  autocompleteField:{

    maxWidth:325
  }
}));

export default function MultiChip(props) {
  const classes = useStyles();



  //console.log(props.tags)

  const onTagsChange = (values) => {
      // This will output an array of objects
      // given by Autocompelte options property.
     // console.log(values);


      props.setFilterTags(values)
   
  }

  return (
    <div className={classes.root}>
       <FormLabel component="legend">Filter by Tag</FormLabel>
     
      <Autocomplete
        multiple
        size="small"
        id="tags-filled"
        options={topTags.map((option) => option.tag)}
        // onChange={props.logOut()}
        onChange={(e,values) => onTagsChange(values)}
    
        
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip variant="outlined" label={option} {...getTagProps({ index })} />
          ))
        }
        renderInput={(params) => (
          <TextField {...params} className={classes.autocompleteField} variant="filled"  placeholder="e.g. 'data science'" />
        )}
      />
    </div>
  );
}




const topTags = [{"tag":"3d","count":1},
{"tag":"advanced analytics","count":1},
{"tag":"agent-based modeling","count":1},
{"tag":"analytics platform","count":1},
{"tag":"api","count":1},
{"tag":"apple maps","count":1},
{"tag":"arcgis","count":1},
{"tag":"arcgis api","count":1},
{"tag":"arcgis earth","count":1},
{"tag":"arcgis online","count":1},
{"tag":"arcgis pro","count":1},
{"tag":"architecture","count":1},
{"tag":"artificial intelligence","count":1},
{"tag":"atmospheric correction","count":1},
{"tag":"big data","count":1},
{"tag":"bigquery","count":1},
{"tag":"blender gis","count":1},
{"tag":"business analytics","count":1},
{"tag":"c sharp","count":1},
{"tag":"c++","count":1},
{"tag":"cape town","count":1},
{"tag":"carto","count":1},
{"tag":"cartographic","count":1},
{"tag":"cartography","count":1},
{"tag":"census","count":1},
{"tag":"census academy","count":1},
{"tag":"centroids","count":1},
{"tag":"cinema 4d","count":1},
{"tag":"climate","count":1},
{"tag":"climate change","count":1},
{"tag":"cloud","count":1},
{"tag":"coda","count":1},
{"tag":"covid-19","count":1},
{"tag":"covid19","count":1},
{"tag":"crystal","count":1},
{"tag":"css","count":1},
{"tag":"d3","count":1},
{"tag":"dashboards","count":1},
{"tag":"data analysis","count":1},
{"tag":"data science","count":1},
{"tag":"data visualization","count":1},
{"tag":"database","count":1},
{"tag":"database design","count":1},
{"tag":"database open source","count":1},
{"tag":"database tutorial","count":1},
{"tag":"datasets","count":1},
{"tag":"deep learning","count":1},
{"tag":"design","count":1},
{"tag":"developers","count":1},
{"tag":"distance","count":1},
{"tag":"elevation","count":1},
{"tag":"environment","count":1},
{"tag":"environmental","count":1},
{"tag":"esri","count":1},
{"tag":"fiona","count":1},
{"tag":"folium","count":1},
{"tag":"fortran","count":1},
{"tag":"foss","count":1},
{"tag":"foss4g","count":1},
{"tag":"framework","count":1},
{"tag":"free software","count":1},
{"tag":"fulcrum","count":1},
{"tag":"fusion","count":1},
{"tag":"gdal","count":1},
{"tag":"geodesy","count":1},
{"tag":"geographic","count":1},
{"tag":"geographics","count":1},
{"tag":"geojson","count":1},
{"tag":"geopackage","count":1},
{"tag":"geopandas","count":1},
{"tag":"geoserver","count":1},
{"tag":"geospatial","count":1},
{"tag":"geospatial analysis","count":1},
{"tag":"geospatial big data","count":1},
{"tag":"geotiff","count":1},
{"tag":"gnss","count":1},
{"tag":"google cloud","count":1},
{"tag":"google earth","count":1},
{"tag":"google earth engine","count":1},
{"tag":"google maps","count":1},
{"tag":"google maps api","count":1},
{"tag":"gps","count":1},
{"tag":"gpu","count":1},
{"tag":"grass gis","count":1},
{"tag":"h3","count":1},
{"tag":"health","count":1},
{"tag":"imagery","count":1},
{"tag":"interactive","count":1},
{"tag":"javascript","count":1},
{"tag":"julia","count":1},
{"tag":"landsat","count":1},
{"tag":"leaflet","count":1},
{"tag":"lidar","count":1},
{"tag":"location","count":1},
{"tag":"location intelligence","count":1},
{"tag":"machine","count":1},
{"tag":"machine learning","count":1},
{"tag":"mapbox","count":1},
{"tag":"mapinfo","count":1},
{"tag":"mapserver","count":1},
{"tag":"maptiler","count":1},
{"tag":"matlab","count":1},
{"tag":"mobile","count":1},
{"tag":"model","count":1},
{"tag":"modeling","count":1},
{"tag":"models","count":1},
{"tag":"mongodb","count":1},
{"tag":"nacis","count":1},
{"tag":"open data","count":1},
{"tag":"open source","count":1},
{"tag":"openlayers","count":1},
{"tag":"openstreetmap","count":1},
{"tag":"oracle spatial","count":1},
{"tag":"osm","count":1},
{"tag":"osm building","count":1},
{"tag":"perl","count":1},
{"tag":"php","count":1},
{"tag":"planning","count":1},
{"tag":"plenary","count":1},
{"tag":"postgis","count":1},
{"tag":"postgres","count":1},
{"tag":"postgresql","count":1},
{"tag":"postgresql tutorial","count":1},
{"tag":"processing","count":1},
{"tag":"public","count":1},
{"tag":"qgis","count":1},
{"tag":"radar","count":1},
{"tag":"raster","count":1},
{"tag":"regress","count":1},
{"tag":"remote","count":1},
{"tag":"routing","count":1},
{"tag":"ruby","count":1},
{"tag":"rust","count":1},
{"tag":"s2","count":1},
{"tag":"scala","count":1},
{"tag":"shapefile","count":1},
{"tag":"shapefiles","count":1},
{"tag":"shapely","count":1},
{"tag":"skyline","count":1},
{"tag":"snap","count":1},
{"tag":"software development","count":1},
{"tag":"solaris","count":1},
{"tag":"spacetime","count":1},
{"tag":"spatial","count":1},
{"tag":"spatial analysis","count":1},
{"tag":"spatial analytics","count":1},
{"tag":"spatial data","count":1},
{"tag":"spatial data science","count":1},
{"tag":"spatial data science conference","count":1},
{"tag":"spatialite","count":1},
{"tag":"spring","count":1},
{"tag":"sql","count":1},
{"tag":"state of the map","count":1},
{"tag":"strava","count":1},
{"tag":"survey","count":1},
{"tag":"swift","count":1},
{"tag":"tableau","count":1},
{"tag":"temporal","count":1},
{"tag":"terra","count":1},
{"tag":"territory management","count":1},
{"tag":"tiff","count":1},
{"tag":"tiles","count":1},
{"tag":"tippecanoe","count":1},
{"tag":"training","count":1},
{"tag":"tutorial","count":1},
{"tag":"urban","count":1}]