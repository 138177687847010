import React from 'react';
import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter';
import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight';
import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Button from '@material-ui/core/Button';
import FormLabel from '@material-ui/core/FormLabel';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  parentDiv: {
    marginTop:30
  },
  formLabelLength:{
    marginBottom:10
  }
  
}));


export default function ToggleButtons(props) { 
  const classes = useStyles();

  const handleAlignment = (event, newAlignment) => {

    props.setLengthValue(newAlignment)

  };

  return (
    <div className={classes.parentDiv}>
    <FormLabel className={classes.formLabelLength} component="legend">Filter by Length (Minutes)</FormLabel>
     
    <ToggleButtonGroup
      value={props.lengthValue}
      exclusive
      onChange={handleAlignment}
      aria-label="text alignment"
    >
      <ToggleButton value="allTimes" aria-label="allTimes">
      <Button>All</Button>
      </ToggleButton>
      <ToggleButton value="tenOrLess" aria-label="tenOrLess">
      <Button>1-15</Button>
      </ToggleButton>
      <ToggleButton value="tenOrMore" aria-label="tenOrMore">
      <Button>15+</Button>
      </ToggleButton>
    
   
    </ToggleButtonGroup>
    </div>
  );
}