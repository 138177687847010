import React, { useState, useEffect } from "react"
import SEO from "../components/seo"
import { Link, StaticQuery, useStaticQuery, graphql } from "gatsby"
import { withStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import "../landing.css"
import { makeStyles } from "@material-ui/core/styles"
import SimpleSelect from "../components/SimpleSelect"
import VideosGrid from "../components/VideosGrid"

import MultiChip from "../components/MultiChip"
import YearSlider from "../components/YearSlider"

import RadioGroup from "../components/RadioGroup"

import ButtonGroupLength from "../components/ButtonGroupLength"

import Box from "@material-ui/core/Box"
import Chip from "@material-ui/core/Chip"
import DoneIcon from "@material-ui/icons/Done"

import { countBy, filter } from "lodash"

const actualShuffler = a => {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[a[i], a[j]] = [a[j], a[i]]
  }
  return a
}

const useStyles = makeStyles(theme => ({
  logo: {
    height: 28,
    marginRight: theme.spacing(2),
  },
  drawerList: {
    width: 250,
  },
  explore: {
    marginTop: 30,
    [theme.breakpoints.up('md')]: {
      maxWidth: 1440
    }
 
 
  
  },
  sortBy: {
    marginRight: 10,
    fontSize:'1.3em',
    fontWeight:300,
    color: "#8d8d8d",
   
  },
  indexParent: {
    
    [theme.breakpoints.up('md')]: {
      maxWidth: 1440
    }
  },
  flexBoxExploreSort: {
    display:"flex",
    justifyContent:"flex-end",
    alignItems:"center",
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
    }
  }
}))



export default function LandingPage() {
  const classes = useStyles()

  //const [filteredItems, setfilteredItems] = useState([]);

  const data = useStaticQuery(graphql`
    query MainIndexQuery {
      allItem(sort: { fields: view_count, order: DESC }) {
        edges {
          node {
            thumbnail_link
            view_count
            title
            tags
            publishedAt
            channelName
            description
            video_id
            view_count
            duration
           
          }
        }
      }
    }
  `)

  const [filteredItems, setfilteredItems] = useState(data.allItem.edges)
  const [filterTags, setFilterTags] = useState([])

  const [yearValue2, setYearValue2] = React.useState([])
  const [lengthValue, setLengthValue] = React.useState('allTimes')


  


  const filterYearValue = (array) => {
    if (yearValue2.length === 0){
      return array
    }
    else if(yearValue2 === 2017){

      return array.filter((item) => item.node.publishedAt.slice(0, 4) <= 2017)
    }
    else {
      console.log(array[0].node.publishedAt)
      return array.filter((item) => item.node.publishedAt.slice(0, 4) === yearValue2)
    }

  }
  const filterTagValues = (array) => {
    if(filterTags.length > 0){
      return array.filter((item) => item.node.tags.some(tag => filterTags.includes(tag.toLowerCase())))
    }
    else {
      return array
    }
   
  }

  const filterTimeValues = (array) => {
    if(lengthValue === 'tenOrLess'){

      return array.filter((item) => item.node.duration <= 900)
    }
    else if (lengthValue === 'tenOrMore') {
      return array.filter((item) => item.node.duration >= 900)

    }
    else {
      return array
    }
   
  }

  const actualShuffler = (a) => {
    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      ;[a[i], a[j]] = [a[j], a[i]]
    }
    return a
  }


  useEffect(() => {

      let arrayOfItems = data.allItem.edges;

      arrayOfItems = filterTagValues(arrayOfItems)
      arrayOfItems = filterYearValue(arrayOfItems)
      arrayOfItems = filterTimeValues(arrayOfItems)
      arrayOfItems = actualShuffler(arrayOfItems)


      setfilteredItems(arrayOfItems)


    // I want to call setState here
  }, [filterTags, yearValue2, lengthValue])




  return (
    <>
      <Container className={classes.indexParent}>
        <Container className={classes.explore}>
          <Grid container={true} spacing={2}>
            <Grid item={true} md={6} xs={12}>
              <Typography variant="h5">
                <strong>Explore</strong>
              </Typography>
            </Grid>

            <Grid item={true} md={6} xs={12}>
              <Box
           
  
                className={classes.flexBoxExploreSort}
              >
                <Typography
                  align="right"
                  variant="h5"
                  className={classes.sortBy}
                >
                  <strong>{filteredItems.length} Videos</strong>
                </Typography>

            
              </Box>
            </Grid>
          </Grid>
        </Container>

        <Container className={classes.explore}>
          <Grid container={true} spacing={2}>
            <Grid item={true} md={3} xs={12}>
     
              <MultiChip setFilterTags={setFilterTags} />
              <br />

              <RadioGroup
                yearValue2={yearValue2}
                setYearValue2={setYearValue2}
              />
              <br />
              <ButtonGroupLength setLengthValue={setLengthValue} lengthValue={lengthValue} />
            </Grid>

            <Grid item={true} md={9} xs={12}>
              <VideosGrid locations={filteredItems} />
            </Grid>
          </Grid>
        </Container>
      </Container>
    </>
  )
}
