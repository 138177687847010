import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';

const useStyles = makeStyles({
  root: {
    width: 300,
  },
});

function valuetext(value) {
  return `${value}°C`;
}
const marks = [
  {
    value: 2015,
    label: '2015',
  },
  {
    value: 2016,
    label: '2016',
  },
  {
  value: 2017,
  label: '2017',
},
{
  value: 2018,
  label: '2018',
},
{
  value: 2019,
  label: '2019',
},
{
  value: 2020,
  label: '2020',
},
{
  value: 2021,
  label: '2021',
},
{
  value: 2022,
  label: '2022',
},


 
];

export default function YearSlider(props) {
  const classes = useStyles();
 
  const handleChange = (event, newValue) => {
    props.setYearValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Typography id="range-slider" gutterBottom>
        Year
      </Typography>
      <Slider
        value={props.yearValue}
        step={1}
        min={2016}
        max={2022}
        aria-labelledby="discrete-slider-always"
        marks={marks}
        valueLabelDisplay="on"
        onChange={handleChange}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
        getAriaValueText={valuetext}
      />
    </div>
  );
}